import request from '@/utils/request'
// 获取商品分类
export function queryGoodsType(params) {
  return request({
    method: "get",
    url: "/shd-operation/lrGoodsClassify/queryGoodsType",
    params
  });
}

//获取门店列表
export function getStoreList() {
  return request({
    url: "/goods-service/store/listOfPage",
    method: "get"
  });
}

// 查询所有角标
export function queryAllCornerMark(data) {
  return request({
    url: "/shd-operation/lrGoodsCorner/queryAllCornerMark",
    method: "post",
    data
  });
} 